<template>
  <div class="notifications">
    <b-dropdown no-caret toggle-class="user-drop-button" offset="-100">
      <template #button-content>
        <font-awesome-icon icon="bell" class="notification-icon "></font-awesome-icon>
      </template>
      <b-dropdown-header class="notification-header">
        Notifications
      </b-dropdown-header>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item disabled>No Notifications</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>

export default {
  name: "notifications",
  data() {
    return {
      notifications: []
    }
  },
  components: {},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }
      return false;
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>
  .notifications{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .notification-icon {
    background: transparent;
    color: white;
    font-size: 2em;
  }

  .notification-header {
    font-size: 1em;
    font-weight: 500;
    color: black;
    text-decoration: none;
  }

  .notification-item {

  }


</style>
