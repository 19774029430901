<template>
  <div class="cluster-selector-buttons">
      <div v-for="(cluster, idx) of clusters" v-bind:key="idx" @click="selectCluster(cluster)"
          class="rebutton-icon" :class="{'selected': selectedCluster===cluster.value}">
        <div class="icon" :class="{'selected': selectedCluster===cluster.value}">
          <font-awesome-icon icon="chart-pie" size="1x" />
        </div>
        {{cluster.text}}
      </div>
  </div>
</template>

<script>
export default {
  name: "cluster-selector-buttons",
  data() {
    return {
      clusters: null,
      selectedCluster: null
    }
  },
  components: {},
  methods: {
    selectCluster(cluster) {
      this.$store.commit('dashboard/selectCluster', cluster.value)
    }
  },
  mounted() {
  },
  watch: {
    '$store.state.dashboard.data': function (newData) {
      if (newData) {
        // Return objects formatted to work with a standard drop down/checkbox list/etc
        this.clusters = Object.keys(newData.cluster_information).map(x => {
          let data = {
            value: x
          }
          if (isNaN(x)) {
            data.text = 'TOTAL'
          } else {
            data.text = 'CLUSTER '+ (parseInt(x) + 1)
          }
          return data
        })
      } else {
        this.clusters = null
      }
    },
    '$store.state.dashboard.selectedCluster': function (newCluster) {
      if (Number.isInteger(newCluster)) {
        newCluster = newCluster.toString()
      }
      this.selectedCluster = newCluster
    }
  }
}
</script>

<style scoped>
  .cluster-selector-buttons{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rebutton-icon {
    font-size: 0.8em;
  }

</style>
