<template>
  <div class="data-export-buttons">
    <div v-if="dataset">
      <div class="rebutton-icon" @click="csv_download">
        <div class="icon" >
          <font-awesome-icon icon="file-csv" size="1x" />
        </div>
        <div>CSV EXPORT</div>
      </div>
      <div class="rebutton-icon" @click="facebook_download">
        <div class="icon" >
          <font-awesome-icon icon="file-csv" size="1x" />
        </div>
        <div>FACEBOOK EXPORT</div>
      </div>
    </div>
  </div>
</template>

<script>

import DashboardService from "../../services/dashboard.service";

export default {
  name: "data-export-buttons",
  data() {
    return {
      dataset: null,
      current_cluster_id: null
    }
  },
  components: {},
  methods: {
    async facebook_download() {
      let cluster_id = parseInt(this.$store.state.dashboard.selectedCluster, 0)
      if (isNaN(cluster_id)) {
        cluster_id = 'total'
      }
      let resp = await DashboardService.getReportFB(this.$store.state.dashboard.currentDataId, cluster_id)
      let fbData = resp.data

      let clusterIdText = cluster_id
      if (clusterIdText !== 'total') {
        clusterIdText += 1
      }
      let location = this.$store.state.dashboard.schema.boundary_name
      let filename = `Facebook_Export_${location}_cluster_${clusterIdText}_.csv`
      // TODO - Change encoding based on whatever the FB data actually is.

      this.downloadFile(filename, fbData)
      return false;
    },
    async csv_download() {
      let resp = await DashboardService.getReportCSV(this.$store.state.dashboard.currentDataId)
      let csvData = resp.data

      let location = this.$store.state.dashboard.schema.boundary_name
      let filename = `CSV_Export_${location}.csv`;

      this.downloadFile(filename, csvData)
    },
    downloadFile(filename, text) {
      let pom = document.createElement('a');
      pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      pom.setAttribute('download', filename);

      if (document.createEvent) {
        let event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
      }
      else {
        pom.click();
      }
    }
  },
  mounted() {
  },
  watch: {
    '$store.state.dashboard.data': function (newData) {
      if (newData) {
       this.dataset = newData
      } else {
        this.dataset = null
      }
    },
    '$store.state.dashboard.selectedCluster': function (newCluster) {
      if (Number.isInteger(newCluster)) {
        newCluster = newCluster.toString()
      }
      this.current_cluster_id = newCluster
    }
  }
}
</script>

<style scoped>
  .data-export-buttons{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rebutton-icon {
    font-size: 0.8em;
  }
</style>
