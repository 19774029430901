<template>
  <div class="background">
    <div class="reboundary-card">
      <h1>Privacy Policy</h1>
      <p>Privacy Policy goes here...</p>

      <b-button class="rebutton" @click="$router.back()">
        Back
      </b-button>
    </div>

  </div>
</template>

<script>

import API_URL from "@/services/url.js";

export default {
  name: 'Privacy',
  components: {  },
  data() {
    return {
      content: '',
      API_URL
    };
  },
  mounted() {

    // UserService.getPublicContent().then(
    //   response => {
    //     this.content = response.data;
    //
    //   },
    //   error => {
    //     // this.content =
    //     //   (error.response && error.response.data) ||
    //     //   error.message ||
    //     //   error.toString();
    //     this.$router.push('/auth/login')
    //   }
    // );
  }
};
</script>

<style scoped>

.background {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  background: url('../assets/img/Reboundary_BG.svg'), #0961a9;
  background-size: cover;
  background-repeat: no-repeat;
}

.reboundary-card {
  margin: 5em;
}

</style>
