const run_analysis_on_value = function(value, rowdata) {
  if (!value) {
    return;
  }

  if (rowdata['analysis'] === 'mean') {
    value = mean(value);
  } else if (rowdata['analysis'] === 'median') {
    value = median(value);
  } else if (rowdata['analysis'] === 'count') {
    value = value.length;
  } else if (rowdata['analysis'] === 'sum') {
    // sum value
  }

  if (rowdata['multiplier']) {
    value = rowdata['multiplier'] * value;
  }
  // TODO: Centralise this handling of values - it is needed elsewhere
  if (rowdata['data-type'] === 'float') {
    let num_digits = rowdata['float-precision'];
    if (!num_digits) {
      num_digits = 0;
    }
    value = value.toLocaleString(undefined, {
      maximumFractionDigits: num_digits
    });
  } else if (rowdata['data-type'] === 'integer') {
    value = value.toLocaleString(undefined, { maximumFractionDigits: 0 });
  } else if (rowdata['data-type'] === 'currency') {
    value = '$' + value.toLocaleString(undefined, { maximumFractionDigits: 0 });
  } else if (rowdata['data-type'] === 'percentage') {
    value = value.toLocaleString(undefined, { maximumFractionDigits: 1 }) + '%';
  } else if (rowdata['data-type'] === 'occupation') {
    value = human_readable_strings[value];
  } else if (rowdata['data-type'] === 'datetime') {
    value = moment(value).format(human_datetime_format);
  } else if (rowdata['data-type'] === 'list') {
    let key = rowdata['meta-key'];
    if (!key) {
      key = rowdata['data-key'];
    }
    console.log('list-item');
    console.log(value);
    let select_code =
      "<div class='filtered_list_parent filtered_list_parent_short'>";
    for (let item of value) {
      select_code += `<label class="btn dataset_select_button">${item}</label> `;
    }
    value = select_code + '</div>';
  }

  if (rowdata['data-unit']) {
    value = `${value} ${rowdata['data-unit']}`;
  }

  if (value === null || value === undefined) {
    value = ''; // Allows blank cells in table.
  }
  return value;
};
export default run_analysis_on_value;

function mean(values) {
  if (!values) {
    return 0;
  }
  let sum = 0;
  for (var i = 0; i < values.length; i++) {
    sum += values[i]; //don't forget to add the base
  }

  return sum / values.length;
}

function median(raw_values) {
  if (!raw_values) {
    return 0;
  }
  let values = [...raw_values].sort(sortNumber);
  console.log(values);
  var half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];
  else return (values[half - 1] + values[half]) / 2.0;
}

function sortNumber(a, b) {
  return a - b;
}

// TODO Support quantiles
function quantile(array, percentile) {
  if (!array) {
    return 0;
  }
  array.sort(sortNumber);
  index = percentile * (array.length - 1);
  if (Math.floor(index) === index) {
    result = array[index];
  } else {
    let i = Math.floor(index);
    fraction = index - i;
    result = array[i] + (array[i + 1] - array[i]) * fraction;
  }
  return result;
}

// TODO Support filtering outliers
function filterOutliers(someArray) {
  if (someArray.length < 4) return someArray;

  let values, q1, q3, iqr, maxValue, minValue;

  values = someArray.slice().sort((a, b) => a - b); //copy array fast and sort

  if ((values.length / 4) % 1 === 0) {
    //find quartiles
    q1 = (1 / 2) * (values[values.length / 4] + values[values.length / 4 + 1]);
    q3 =
      (1 / 2) *
      (values[values.length * (3 / 4)] + values[values.length * (3 / 4) + 1]);
  } else {
    q1 = values[Math.floor(values.length / 4 + 1)];
    q3 = values[Math.ceil(values.length * (3 / 4) + 1)];
  }

  iqr = q3 - q1;
  maxValue = q3 + iqr * 1.5;
  minValue = q1 - iqr * 1.5;

  return values.filter(x => x >= minValue && x <= maxValue);
}

function removeZeros(values) {
  return values.filter(x => x > 0);
}
