import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth.module';
import {dashboard} from './dashboard.module'
import {query} from './query.module'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        dashboard,
        query
  }
});
