import axios from 'axios';
import API_URL from '@/services/url.js';
import {authHeader} from './auth-header';

class AuthService {
  login(user, newPassword=null) {
      let data = {
          username: user.username,
          password: user.password
      }
      if (newPassword) {
          data.new_password = newPassword
      }
    return axios
      .post(API_URL + 'auth/signin', data)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        if (response.data.passwordResetRequired){
            this.$router.push('/auth/reset-password')
        }

        return response.data;
      });
  }

  edit(edit_data) {
    let user = edit_data.user;
    let organisation_create_if_not_exists = edit_data.organisation_create_if_not_exists;
    return axios
      .post(API_URL + `user/edit/${user.user_id}`, {
        display_name: user.display_name,
        email: user.email,
        password: user.password,
        verify_password: user.verify_password,
        organisation: user.organisation,
        organisation_create_if_not_exists: organisation_create_if_not_exists
      },
      { headers: authHeader() });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'user/register', {
        display_name: user.display_name,
      email: user.email,
      password: user.password,
      organisation: user.organisation
    },
        { headers: authHeader() });
  }
}

export default new AuthService();
