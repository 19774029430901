import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {loggedIn: false}, user: null};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({commit}, payload) {
            return AuthService.login(payload.user, payload.newPassword).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        edit({commit}, user) {
            return AuthService.edit(user).then(
                data => {
                    commit('userEditSuccess', data.data);
                    return Promise.resolve(user);
                },
                error => {
                    commit('userEditFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({commit}) {
            AuthService.logout();
            commit('logout');
        },

    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        userEditSuccess(state, user) {
            // Log the user in again after
            state.status.loggedIn = true;
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        userEditFailure(state) {
            console.error(state)
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
            localStorage.removeItem('user');
        }
    },
    getters: {
        hasRole: (store) => (roleName) => {
            console.log(store.user.roles.some(role => role.name === roleName)) // store.user.roles.find(role => role.name === roleName)
            return store.user.roles.some(role => role.name === roleName)
        },
        hasAnyRole: (store) => (roles) => {
            if (!store.user.roles) return false
            console.log(store.user.roles.some(role => roles.includes(role.name)))  // store.user.roles.find(role => role.name === roleName)
            return store.user.roles.some(role => roles.includes(role.name))
        },
    }
}
