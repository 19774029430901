import axios from 'axios';
import API_URL from '@/services/url.js';
import authHeader from './auth-header';

class DashboardService {
  getAllDashboards() {
    return axios.get(API_URL + 'dashboards', { headers: authHeader() });
  }

  getDashboardSchema(dashboard_id) {
    return axios.get(API_URL + `dashboard/schema/${dashboard_id}`, {
      headers: authHeader()
    });
  }

  runDashboard(dashboard_name, parameters) {
    return axios.post(API_URL + `dashboard/run/${dashboard_name}`, parameters, {
      headers: authHeader()
    });
  }

  createDashboard(dashboard_data) {
    return axios.post(
      API_URL + 'dashboard/create',
      dashboard_data,
      {
        headers: authHeader()
      }
    )
  }

  updateDashboard(dashboard_id, updated_dashboard){
    return axios.post(
      API_URL + `dashboard/update/${dashboard_id}`,
      updated_dashboard,
      {
        headers: authHeader()
      }
    );
  }

    async reportStart(report_name, boundary_name, start_date, datasets_query, clusters) {
        return axios.post(
            API_URL + `report/start`,
            {
                report_name,
                boundary_name,
                start_date,
                datasets_query,
                k: clusters
            },
            {
                headers: authHeader()
            }
        );
    }

  async reportStatus(report_id) {
      return axios.post(
      API_URL + `report/status/` + report_id,
          {},
          {
              headers: authHeader()
          }
    )
  }

    async getReport(report_id) {
        return axios.post(
            API_URL + `report/get/` + report_id,
            {},
            {
                headers: authHeader()
            }
        )
    }

    async getReportCSV(report_id) {
        return axios.post(
            API_URL + `report/export/csv/` + report_id,
            {},
            {
                headers: authHeader()
            }
        )
    }

    async getReportFB(report_id, cluster_id) {
        return axios.post(
            API_URL + `report/export/facebook/${report_id}/${cluster_id}`,
            {},
            {
                headers: authHeader()
            }
        )
    }

    async deleteReport(report_id) {
        return axios.post(
            API_URL + `report/delete/${report_id}`,
            {},
            {
                headers: authHeader()
            }
        )
    }

    async rerunReport(report_id) {
        return axios.post(
            API_URL + `report/rerun/${report_id}`,
            {},
            {
                headers: authHeader()
            }
        )
    }
}

export default new DashboardService();
