import axios from 'axios';
import API_URL from '@/services/url.js';
import authHeader from './auth-header';

class SearchService {
  search(search_query) {
    return axios.post(API_URL + 'search', search_query, {
      headers: authHeader()
    });
  }
}

export default new SearchService();
