import axios from 'axios';
import API_URL from '@/services/url.js';
import authHeader from './auth-header';

class DatasetService {
    datasetSearch(search_query) {
        return axios.post(API_URL + 'dataset/search', search_query, {
            headers: authHeader()
        });
    }

    addDataset(dataset_file, dataset_parameters) {
        return axios.post(
            API_URL + `dashboard/upload`,
            dataset_parameters,
            {
                headers: authHeader()
            }
        );
    }

    getDataset(datasetId) {
        return axios.post(API_URL + 'dataset/get/' + datasetId, {}, {
            headers: authHeader()
        });
    }

    getAllDatasetFeatures(){
        return axios.post(API_URL + 'dataset/search', {'search_limit': null}, {
            headers: authHeader()
        });
    }
}

export default new DatasetService();
