import DashboardService from '../services/dashboard.service';


export const dashboard = {
    namespaced: true,
    state: {
        currentDataId: null, // Dashboard ID of the currently loaded data
        currentSchemaId: null, // Currently loaded Schema
        reportList: null,
        reportListTimestamp: 0,
        schema: null,
        data: null,
        groups: null,
        selectedCluster: null
    },
  actions: {
    async getDashboards({commit}) {
        let resp = await DashboardService.getAllDashboards()
        commit('updateReportList', resp.data.dashboards)
    },
    async getDashboardSchema({commit}, dashboardId) {
        let resp = await DashboardService.getDashboardSchema(dashboardId)
        commit('updateSchema', resp.data.dashboard)
        commit('updateSchemaId', dashboardId)
    },
      async getDashboardData({commit}, payload) {
          let resp = await DashboardService.getReport(payload.dashboardId)
          commit('updateDataId', payload.dashboardId)
          commit('updateData', resp.data)
      },
  },
  mutations: {
      updateReportList(state, reports) {
        state.reportList = reports
        state.reportListTimestamp = Date.now()
      },
      updateSchema(state, schema) {
          state.schema = schema
      },
      updateSchemaId(state, schemaId) {
          state.currentSchemaId = schemaId
      },
      updateData(state, data) {
          state.data = data
      },
      updateDataId(state, newId) {
          state.currentDataId = newId
      },
      selectCluster(state, clusterId) {
          state.selectedCluster = clusterId
      },

  }
};
