<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {
  },
};
</script>


<style>

div.card{
  /* Fancy rounding */
  border: 4pt solid #2b6baa;
  border-radius: 16pt 0 16pt 0;
  margin-bottom: 8pt;
}
</style>
