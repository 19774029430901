import axios from 'axios';
import API_URL from '@/services/url.js';
import authHeader from './auth-header';

class BoundaryService {
boundarySearch(search_query) {
return axios.post(API_URL + 'boundary/search', search_query, { headers: authHeader() });
}

    getBoundary(boundaryId) {
        return axios.post(API_URL + `boundary/get/${boundaryId}`, {}, { headers: authHeader() });
    }

    reportableLocations(search_query) {
        return axios.post(API_URL + 'boundary/reportable', search_query, { headers: authHeader() });
    }
}

export default new BoundaryService();
