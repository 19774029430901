<template>
  <div class="main">
    <div class="half-logo-layout">
      <div class="logo">
        <img src="@/assets/img/Reboundary_WHITE.png">
      </div>
      <div class="sub-heading">
        See space differently
      </div>
    </div>
    <div class="content">
      <router-view/>
    </div>

  </div>
</template>

<script>
export default {
  name: "HalfLogoLayout"
}
</script>

<style scoped>
  .main{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .content {
    width: 100%;
  }
  .half-logo-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    background: url('../../assets/img/Reboundary_BG.svg'), #0961a9;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .logo {
    /*left: 50%;*/
    /*transform: translate(-50%, 0);*/
    margin-top: 15%;
    width: 100%;
    text-align: center;
  }
  .logo img {
    width: 45%;
    max-width: 400px;
  }

  .sub-heading {
    text-align: center;
    color: white;
    font-size: 2em;
    margin-bottom: 15%;
    font-weight: 500;
  }

</style>
