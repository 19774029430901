import DatasetService from '../services/dataset.service';
import BoundaryService from '../services/boundary.service'
import CategoryService from '../services/category.service'
import Vue from "vue";

export const query = {
    namespaced: true,
    state: {
        locations: null,
        selected_location: null,
        datasets: null,
        selected_datasets: null,
        categories: null,
        selected_categories: null,
        selected_dataset: null,
        selected_dataset_min: null,
        selected_dataset_max: null,
        selected_dataset_mean: null,
        selected_dataset_date: null,
        selected_dataset_agg_method: 'sum',
        dataset_agg_funcs: {
            sum: {
                text: 'Sum',
                func: (values) => values.reduce((a, b) => a + b, 0),
            },
            mean: {
                text: 'Mean',
                func: (values) => (values.reduce((a, b) => a + b, 0) / values.length).toFixed(2),
            },
        }
    },
  actions: {
    async getBoundaries({commit}) {
        // TODO - Add API endpoint for Getting locations
        let resp = await BoundaryService.boundarySearch({classification: 'LGA', search_limit: 10000})
        let locations = resp.data.boundaries
        // let locations = ['Ballarat', 'Melbourne', 'Sydney', 'Canberra', 'Coonbarabran']
        commit('updateLocations', locations)
    },
    async getDatasets({commit}) {
        let resp = await DatasetService.datasetSearch({})
        if (resp.data.hasOwnProperty('datasets')) {
            commit('updateDatasets', resp.data.datasets)
        }
    },
    async getCategories({commit}) {
          let resp = await CategoryService.categorySearch({})
          if (resp.data.hasOwnProperty('categories')) {
              commit('updateCategories', resp.data.categories)
          }
      },
    async getSelectedDataset({commit}, payload) {
      let resp = await DatasetService.getDataset(payload.datasetId)
      commit('updateSelectedDataset', resp.data)
    },
      async setSelectedDatasetDate({commit}, payload) {
          // let resp = await DatasetService.getDataset(payload.datasetId)
          commit('updateSelectedDatasetDate', payload.date)
      },
  },
  mutations: {
      updateLocations(state, newLocations) {
        state.locations = newLocations
      },
      updateDatasets(state, newDatasets) {
          state.datasets = newDatasets
      },
      updateCategories(state, newCategories) {
          state.categories = newCategories
      },
      selectLocation(state, location) {
          state.selected_location = location
      },
      selectDatasets(state, datasets) {
          state.selected_datasets = datasets
      },
      addDataset(state, dataset) {
          if (!state.selected_datasets) {
              state.selected_datasets = []
          }
          state.selected_datasets.push(dataset)
      },
      selectCategories(state, categories) {
          state.selected_categories = categories
      },
      addCategories(state, category) {
          if (!state.selected_categories) {
              state.selected_categories = []
          }
          state.selected_categories.push(category)
      },
      // Used by the Map Explorer to examine Dataset values
      updateSelectedDataset(state, newDataset) {
          this.commit('query/clearSelectedDataset')
          state.selected_dataset = newDataset
          if (state.selected_dataset_date && Object.hasOwn(state.selected_dataset.data, state.selected_dataset_date)) {
              setDatasetStats(state, state.selected_dataset, state.selected_dataset_date)
          }
      },
      clearSelectedDataset(state) {
          state.selected_dataset = null
          state.selected_dataset_max = null
          state.selected_dataset_min = null
          state.selected_dataset_mean = null
      },
      // Used by the Map Explorer to select which data sub-set to use.
      updateSelectedDatasetDate(state, newDate) {
          state.selected_dataset_date = newDate
          if (state.selected_dataset && Object.hasOwn(state.selected_dataset.data, state.selected_dataset_date)) {
              setDatasetStats(state, state.selected_dataset, state.selected_dataset_date)
          }
      },
      updateDatasetAggMethod(state, newMethod) {
          console.log('Set Agg MEthod: ', newMethod)
          state.selected_dataset_agg_method = newMethod
      },
  },
    getters: {
        getDatasetAggFunction: (state) => {
            return state.dataset_agg_funcs[state.selected_dataset_agg_method].func
        },
        getSelectedDatasetValue: (state, getters) => (boundary) => {
            let ds = state.selected_dataset
            let dsDate = state.selected_dataset_date
            // console.log(boundary)
            if (dsFormatToBoundaryClass(ds) !== boundary.classification) {
                return getters.getDatasetAggFunction(boundary.contained_boundary_names.map(name => ds.data[dsDate].data[name]))
            } else {
                if (Object.hasOwn(ds.data, dsDate) && Object.hasOwn(ds.data[dsDate].data, boundary.name)) {
                    return ds.data[dsDate].data[boundary.name]
                } else {
                    return '0'
                }
            }
        }
    }
};

function dsFormatToBoundaryClass(dataset) {
    let datasetFormatLookup = {
        sa1_statistic: 'SA1',
        sa2_statistic: 'SA2',
        sa3_statistic: 'SA3',
        sa4_statistic: 'SA4'
    }
    return datasetFormatLookup[dataset.format]
}

function setDatasetStats(state, dataset, date) {
    let values = Object.values(dataset.data[date].data)
    Vue.set(state, 'selected_dataset_min', Math.min(...values))
    Vue.set(state, 'selected_dataset_max', Math.max(...values))
    Vue.set(state, 'selected_dataset_mean', values.reduce((a, b) => a + b, 0) / values.length)
    // state.selected_dataset_max = Math.max(...values)
    // state.selected_dataset_mean = values.reduce((a, b) => a + b, 0) / values.length
    // console.log('MEAN', state.selected_dataset_mean)
}
