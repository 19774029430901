import Vue from 'vue';
import App from './App.vue';
import {router} from './router';
import store from './store';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VeeValidate from 'vee-validate';
import Vuex from 'vuex';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import {
    faMapMarkedAlt,
    faUser,
    faCog,
    faLock,
    faCompass,
    faSpinner,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faRedo,
    faGlobe,
    faCommentDots,
    faChartPie,
    faFileCsv,
    faCloudDownloadAlt,
    faSearch,
    faDatabase,
    faBell,
    faExpand,
    faCompressAlt,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCircle,
    faCheckCircle,
    faTimesCircle,
    faSync,
    faPlayCircle,
    faMapMarkerAlt,
    faTimes,
    faExclamationTriangle,
    faCheck,
    faMapMarker
} from '@fortawesome/free-solid-svg-icons';
import {faLayerPlus} from '@fortawesome/pro-regular-svg-icons';
import VueShowdown from 'vue-showdown';
import run_analysis_on_value from './filters/run_analysis_on_value';
import * as VueGoogleMaps from 'vue2-google-maps';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import {faFileSearch, faHome} from '@fortawesome/pro-light-svg-icons';
import '@/custom.css'

Vue.config.productionTip = false;

Vue.use(VueShowdown, {
    options: {
        emoji: true
    }
});
library.add(
    faHome,
    faUser,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faMapMarkedAlt,
    faLayerPlus,
    faFileSearch,
    faSpinner,
    faCog,
    faLock,
    faCompass,
    faRedo,
    faGlobe,
    faCommentDots,
    faChartPie,
    faFileCsv,
    faCloudDownloadAlt,
    faSearch,
    faDatabase,
    faBell,
    faExpand,
    faCompressAlt,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCircle,
    faCheckCircle,
    faTimesCircle,
    faSync,
    faPlayCircle,
    faMapMarkerAlt,
    faTimes,
    faExclamationTriangle,
    faCheck,
    faMapMarker
)

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDCTDwaZJr25OWwAf76ZQRTFiHjnGgJr9s',
        libraries: 'places,drawing' // necessary for places input
    }
});

// Changed the name of the injected 'fields' reference, since it conflicts with BV Tables otherwise.
Vue.use(VeeValidate, {fieldsBagName: 'veeFields'});
Vue.component('font-awesome-icon', FontAwesomeIcon);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(Vuex);

Vue.filter('run_analysis_on_value', run_analysis_on_value);

// Automatically register all components in the /components/reporting folder
const requireComponent = require.context(
  // The relative path of the components folder
  './components/reporting',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);

// Automatically register all components in the /components/reporting folder
// const requireComponent = import.meta.glob(
//     ['./components/reporting/*.vue', './components/reporting/*.js'],
//     {eager: true})


Vue.component('v-select', vSelect);

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            // Gets the file name regardless of folder depth
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    );

    // Register component globally
    Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    );
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
