import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import HalfLogoLayout from "./components/layouts/HalfLogoLayout";
import store from './store/index'
import NavigationLayout from "./components/layouts/NavigationLayout";
import MissingPage from "./views/MissingPage";
import Terms from "./views/Terms"
import Privacy from "./views/Privacy";

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '',
            component: Home,
        },
        {
            path: '/terms',
            component: Terms,
        },
        {
            path: '/privacy',
            component: Privacy,
        },
        {
            path: '/auth',
            component: HalfLogoLayout,
            children: [
                {
                    path: 'login',
                    name: 'login',
                    component: () => import('./components/LoginForm')
                },
                {
                    path: 'forgotten-password',
                    name: 'forgotten-password',
                    component: () => import('./components/PasswordForm')
                },
                {
                    path: 'register',
                    name: 'register',
                    component: () => import('./components/RegisterForm')
                },
                {
                    path: 'reset-password-landing',
                    name: 'reset-password-landing',
                    component: () => import('./components/PasswordResetLanding')
                },
                {
                    path: 'reset-password/:token',
                    name: 'reset-password',
                    component: () => import('./components/PasswordResetForm')
                }
            ]
        },
        {
            path: '/home',
            component: NavigationLayout,
            meta: {
                requireAuth: true
            },
            children: [
                {
                    path: '',
                    name: 'dashboards',
                    component: () => import('./views/Dashboards.vue')
                },
                {
                    path: 'locations',
                    name: 'locations',
                    component: () => import('./components/SelectBoundary')
                },
                {
                    path: 'query',
                    name: 'query',
                    component: () => import('./components/QueryBuilder')
                },
                {
                    path: 'jobs/:job_id',
                    name: 'jobs',
                    component: () => import('./components/JobLoader')
                },
                {
                    path: 'user',
                    name: 'user',
                    // lazy-loaded
                    component: () => import('./views/BoardUser.vue')
                },
                {
                    path: 'dashboard/:dashboard_name',
                    component: () => import('./views/DashboardSingle.vue')
                },
                {
                    path: 'reports/:dashboard_name',
                    name: 'dashboard_viewer',
                    component: () => import('./views/QueryDashboard.vue')
                },
                {
                    path: 'reports/:dashboard_name/:page_id',
                    component: () => import('./views/QueryDashboard.vue')
                },
                {
                    path: 'dashboard/create',
                    name: 'create_dashboard',
                    // lazy-loaded
                    component: () => import('./views/DashboardCreate.vue')
                },
                {
                    path: 'dashboard/edit/:dashboard_id',
                    name: 'edit_dashboard',
                    // lazy-loaded
                    component: () => import('./views/DashboardEdit.vue')
                },
                {
                    path: 'dashboard/editor/:dashboard_id',
                    name: 'dashboard_editor',
                    // lazy-loaded
                    component: () => import('./views/DashboardEditor.vue')
                },
                {
                    path: 'datasets',
                    name: 'dataset_home',
                    // lazy-loaded
                    component: () => import('./views/datasets/DatasetHome.vue')
                },
                {
                    path: 'datasets/list',
                    name: 'dataset_list',
                    // lazy-loaded
                    component: () => import('./views/datasets/ListDatasets.vue')
                },
                {
                    path: 'datasets/load',
                    name: 'dataset_load',
                    // lazy-loaded
                    component: () => import('./views/datasets/LoadDataset.vue')
                },
                {
                    path: 'profile',
                    name: 'profile',
                    // lazy-loaded
                    component: () => import('./views/Profile.vue')
                },
                {
                    path: 'feedback',
                    name: 'feedback',
                    // lazy-loaded
                    component: () => import('./views/Feedback.vue')
                },
                {
                    path: 'explorer',
                    name: 'explorer',
                    // lazy-loaded
                    component: () => import('./views/BoundaryExplorer.vue')
                },
            ]
        },
        {
            /* Admin views, including organisation admins! */
            path: '/admin',
            component: NavigationLayout,
            meta: {
                requireAuth: true
            },
            children: [
                {
                    path: 'home',
                    name: 'admin_home',
                    component: () => import('./views/admin/AdminHome.vue')
                },
                {
                    path: 'users/register',
                    name: 'register_user',
                    component: () => import('./views/Register.vue')
                },
                {
                    path: 'users/:user_id',
                    name: 'edit_user',
                    component: () => import('./views/UserEdit.vue')
                },
                {
                    path: 'users',
                    name: 'user_admin',
                    component: () => import('./views/UserList.vue')
                }
            ]
        },
        {
            path: '*',
            name: 'missing',
            component: MissingPage
        }
    ]

});

// TODO: Add 'Admin-only' Routes
router.beforeEach((to, from, next) => {
    console.log(to)
    if (to.matched.some(record => record.meta.requireAuth)) {
        const loggedIn = store.state.auth.status.loggedIn;
        if (!loggedIn) {
            next('/auth/login');
        }
    }
    // Proceed to page
    next()

    // const publicPages = ['/login', '/register', '/home'];
    // const authRequired = !publicPages.includes(to.path);
    //
    //
    // // trying to access a restricted page + not logged in
    // // redirect to login page
    // if (authRequired && !loggedIn) {
    //
    // } else {
    //   next();
    // }
});
