<template>
  <div class="navigation-layout">
    <div class="side-panel">
      <div class="logo">
        <a href="/">
          <img src="@/assets/img/Reboundary_WHITE.png">
        </a>
      </div>
      <!--      Static Nav Buttons -->
      <router-link to="/home/locations">
        <div class="rebutton-icon" :class="{'selected': $route.name==='locations' || $route.name==='query'}">
          <div class="icon" :class="{'selected': $route.name==='locations' || $route.name==='query'}">
            <font-awesome-icon icon="map-marked-alt" size="1x"/>
          </div>
          NEW REPORT
        </div>
      </router-link>
      <router-link to="/home/explorer">
        <div class="rebutton-icon" :class="{'selected': $route.name==='explorer'}">
          <div class="icon" :class="{'selected': $route.name==='explorer'}">
            <font-awesome-icon :icon="['fa', 'globe']" size="1x"/>
          </div>
          MAP EXPLORER
        </div>
      </router-link>
      <router-link to="/home">
        <div class="rebutton-icon" :class="{'selected': $route.name==='dashboards'}">
          <div class="icon" :class="{'selected': $route.name==='dashboards'}">
            <font-awesome-icon :icon="['fal', 'home']" size="1x"/>
          </div>
          MY REPORTS
        </div>
      </router-link>
      <router-link to="/home/datasets" v-if="showAdminBoard">
        <div class="rebutton-icon" :class="{'selected': $route.name==='dataset_home'}">
          <div class="icon" :class="{'selected': $route.name==='dataset_home'}">
            <font-awesome-icon icon="database" size="1x"/>
          </div>
          DATASETS
        </div>
      </router-link>
      <router-link to="/admin/users" v-if="showUserAdmin">
        <div class="rebutton-icon" :class="{'selected': $route.name==='user_admin'}">
          <div class="icon" :class="{'selected': $route.name==='user_admin'}">
            <font-awesome-icon icon="user" size="1x"/>
          </div>
          USER ADMIN
        </div>
      </router-link>
      <div v-if="$route.fullPath.includes('reports')" class="menu-section">
        <div class="menu-divider" style="margin-top: 35px"></div>
        <ClusterSelectorButtons></ClusterSelectorButtons>
        <div class="menu-divider"></div>
        <!--      Export Options Menu-->
        <DataExportButtons></DataExportButtons>
      </div>
    </div>
    <!--    MAIN Panel -->
    <div class="main-panel">
      <!--      HEADER Panel-->
      <div class="main-header">
        <div class="header-search">
          <b-input-group>
            <b-form-input placeholder="Search" v-model="searchQuery" @keydown.enter.native="updateSearchResults"></b-form-input>
            <b-input-group-append>
              <font-awesome-icon class="search-icon" icon="search" size="2x"
              v-on:click="updateSearchResults"
              ></font-awesome-icon>
            </b-input-group-append>
          </b-input-group>

          <b-modal id="searchResultsModal"
                   class="search-results modal" v-if="searchResults && showSearchResults"
                   title="Search Results"
          >
            <div class="search-results-list">
              <div class="search-result" v-for="result in searchResults" :key="result.id"
                   @click="searchResults = null; $router.push(result.url)">
                <div class="search-result-title">
                  <font-awesome-icon :icon="getIcon(result.type)" size="1x" class="mr-1"/>
                  {{ getTitle(result.type)}}
                </div>
                <div class="search-result-title">
                  {{ result.title }}
                </div>
                <div class="search-result-description">
                  {{ result.description }}
                </div>
              </div>
            </div>
          </b-modal>
        </div>

        <div class="header-tips">
          <b-dropdown toggle-class="user-drop-button tip-button" offset="-150px" text="Tips" size="sm">
            <h5>Reboundary Tips</h5>
            <b-dropdown-text style="width: 500px;">
              <div class="tip-container">
                <div class="tip-heading">
                  1. Use a Desktop Computer
                </div>
                <div class="tip-content">
                  The site is best viewed on a full-sized desktop monitor, rather than a tablet or small laptop.
                </div>
              </div>
            </b-dropdown-text>
            <b-dropdown-text>
              <div class="tip-container">
                <div class="tip-heading">
                  2. Dive into Details
                </div>
                <div class="tip-content">
                  Click on the report widgets to dive into the details of each Metric. Use the icon in the upper-right
                  corner of the widgets to expand them and view the graphs in more detail.
                </div>
              </div>
            </b-dropdown-text>
            <b-dropdown-text>
              <div class="tip-container">
                <div class="tip-heading">
                  3. View each Cluster
                </div>
                <div class="tip-content">
                  Compare the statistics for each cluster by selecting it from the left-hand navigation menu. This will
                  cause the map and widgets to update to show the details for only the cluster you have selected.
                </div>
              </div>
            </b-dropdown-text>
          </b-dropdown>

        </div>
        <div class="header-notifications">
          <Notifications></Notifications>
        </div>
        <!--User Avatar Drop Down Menu-->
        <b-dropdown no-caret toggle-class="user-drop-button" offset="-20">
          <template #button-content>
            <div class="avatar-container">
              <div class="avatar-text">
                <strong>
                  {{ userName }}
                </strong>
                {{ userOrg }}
              </div>
              <b-avatar :src="require('@/assets/img/avatar_female.svg')"></b-avatar>
            </div>
          </template>
          <b-dropdown-item link-class="user-drop-link" to="/home/profile">
            <template #default>
              <div class="user-menu-item">
                <font-awesome-icon icon="cog" size="1x" class="mr-1"/>
                Account Profile
              </div>
            </template>
          </b-dropdown-item>
          <b-dropdown-item v-if="showAdminBoard" link-class="user-drop-link" to="/admin/home">
            <template #default>
              <div class="user-menu-item">
                <font-awesome-icon size="1x" class="mr-1" icon="lock"/>
                Admin Interface
              </div>
            </template>
          </b-dropdown-item>
          <!--          <b-dropdown-item-button link-class="user-drop-link" to="/home/feedback">-->
          <!--            <template #default>-->
          <!--              <div class="user-menu-item">-->
          <!--                <font-awesome-icon icon="comment-dots" size="1x" class="mr-1" />-->
          <!--                Feedback-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </b-dropdown-item-button>-->
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item-button @click="logOut">
            <template #default>
              <div class="user-menu-item">
                <font-awesome-icon icon="cloud-download-alt" size="1x" class="mr-1"/>
                Sign Out
              </div>
            </template>
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
      <div class="content">
        <router-view :key="$route.fullPath"/>
      </div>
    </div>


  </div>
</template>

<script>
// TODO - This needs a better name
import ClusterSelectorButtons from "../navigation/ClusterSelectorButtons";
import DataExportButtons from "../navigation/DataExportButtons";
import Notifications from "../Notifications";
import SearchService from "@/services/search.service";

export default {
  name: "NavigationLayout",
  data() {
    return {
      clusters: null,
      selectedCluster: null,
      searchQuery: '',
      searchResults: null,
      showSearchResults: false,
      searchResultIcons : {
        'help': 'question-circle',
        'dataset': 'database',
        'boundary': 'map-marker-alt',
      }
    }
  },
  components: {ClusterSelectorButtons, DataExportButtons, Notifications},
  computed: {
    userName() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.display_name;
      } else {
        return 'Not Logged In'
      }
    },
    userOrg() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.organisation;
      } else {
        return ''
      }
    },
    showAdminBoard() {
      return this.$store.state.auth.user.is_admin
    },
    showUserAdmin() {
      console.log(this.$store.state.auth.user)
      return this.$store.getters["auth/hasAnyRole"](['admin', 'manager']) || this.$store.state.auth.user.is_admin
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    },
    async updateSearchResults() {
      if (this.searchQuery.length < 3){
        return
      }
      // Query the API and get results from /search
      let resp = await SearchService.search({'q': this.searchQuery})
      if (resp.data.hasOwnProperty('results')) {
        this.searchResults = resp.data.results;
        this.showSearchResults = true;
        this.$bvModal.show("searchResultsModal")
      }
    },
    selectCluster(cluster) {
      this.$store.commit('dashboard/selectCluster', cluster.value)
    },
    getIcon(searchResultType){
      searchResultType = searchResultType.toLowerCase()
      if (searchResultType in this.searchResultIcons){
        return this.searchResultIcons[searchResultType]
      }else{
        return 'database'
      }
    },
    getTitle(searchResultType){
      return searchResultType.charAt(0).toUpperCase() + searchResultType.slice(1)
    }
  },
  mounted() {
  },
  watch: {
    '$store.state.dashboard.data': function (newData) {
      if (newData) {
        // Return objects formatted to work with a standard drop down/checkbox list/etc
        this.clusters = Object.keys(newData.cluster_information).map(x => {
          let data = {
            value: x
          }
          if (isNaN(x)) {
            data.text = 'TOTAL'
          } else {
            data.text = 'CLUSTER ' + (parseInt(x) + 1)
          }
          return data
        })
      } else {
        this.clusters = null
      }
    },
    '$store.state.dashboard.selectedCluster': function (newCluster) {
      if (Number.isInteger(newCluster)) {
        newCluster = newCluster.toString()
      }
      this.selectedCluster = newCluster
    }
  }
}
</script>

<style scoped>

h5 {
  padding-left: 23px
}

.navigation-layout {
  /*width: 100vw;*/
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #eef0f7;
  overflow: hidden;
}

.main-panel {
  width: calc(100% - 250px);
  left: 250px;
  position: absolute;
  overflow-y: auto;
}

.side-panel {
  background: #005da7;
  width: 250px;
  position: fixed;
  left: 0;
  top: 0;
  /* display: flex; */
  /* flex-direction: column; */
  height: 100%;
  overflow: auto;
}

.content {
  width: 100%;
  background: #eef0f7;
  overflow-y: auto;
}

.main-header {
  background: #014c7f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px 25px 20px 64px;
  align-items: center;
}

.header-search {
  flex-grow: 2;
  border: 1px solid white;
  border-radius: 15px;
}

.header-search input {
  border: none;
  background: transparent;
  color: white;
}

.header-search .input-group-append {
  margin: 5px 5px;
  border-left: 1px solid white;
  color: white;
  font-size: 0.8em;
  padding: 0 5px;
}

.header-notifications {
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
}

.logo {
  /*left: 50%;*/
  /*transform: translate(-50%, 0);*/
  margin: 15% 5% 5% 5%;
  text-align: center;
}

.logo img {
  width: 100%;
  max-width: 400px;
}

.avatar-container {
  color: white;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-self: end;
}

.avatar-text {
  display: flex;
  flex-direction: column;
  text-align: end;
  font-size: 0.75em;
  margin-right: 0.5em;
}

.avatar-container strong {
  color: white;
}

.menu-section {

}

.menu-divider {
  height: 1px;
  background: white;
  margin: 25px 10px 25px 25px;
  width: 200px;
  left: 50%
}

.user-menu-item {

}

.header-tips {
  color: white;
  cursor: pointer;
  margin-left: 1em;
}

.tip-button {
  font-size: 0.75em;
}

.tip-container {
  margin-top: 1em;
}

.tip-heading {
  font-weight: 500;
  font-size: 0.7em;
}

.tip-content {
  font-size: 0.7em;
  word-wrap: anywhere;
  white-space: normal;
}

.rebutton-icon {
  font-size: 0.8em;
}

/* Search Results Formatting */

.search-results-header{
  font-size: 0.8em;
  font-weight: 500;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-bottom: 1px solid #eef0f7;
}

.search-result-description{
  font-size: 0.7em;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-bottom: 1px solid #eef0f7;
}

.search-result-title{
  font-size: 0.8em;
  font-weight: 500;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-bottom: 1px solid #eef0f7;
}

.search-result{
  border: 1px solid #eef0f7;
  background: white;
  cursor: pointer;
  margin: 0.5em 0.5em 0.5em 0.5em;
}

</style>
