<template>
  <div class="background">
  </div>
</template>

<script>
import API_URL from "@/services/url.js";

export default {
  name: 'Home',
  components: {  },
  data() {
    return {
      content: '',
      API_URL
    };
  },
  mounted() {
    if (this.$store.state.auth.status.loggedIn) {
      this.$router.push('/home/')
    } else {
      this.$router.push('/auth/login')
    }

    // UserService.getPublicContent().then(
    //   response => {
    //     this.content = response.data;
    //
    //   },
    //   error => {
    //     // this.content =
    //     //   (error.response && error.response.data) ||
    //     //   error.message ||
    //     //   error.toString();
    //     this.$router.push('/auth/login')
    //   }
    // );
  }
};
</script>

<style scoped>

.background {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  background: url('../assets/img/Reboundary_BG.svg'), #0961a9;
  background-size: cover;
  background-repeat: no-repeat;
}

</style>
